import Vue from "vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "../assets/iconfont/iconfont.css";

import axios from "axios";
import VueAxios from "vue-axios";
import hljs from "highlight.js";
import { stringify } from "querystring";
import bulma from "bulma";
import "bulma/css/bulma.css";
import "../assets/style.css"

Vue.use(ElementUI);
Vue.use(hljs.vuePlugin);
Vue.prototype.stringify = stringify;
Vue.use(bulma);

function process(urls, response) {
    for (const [title, pattern] of Object.entries(urls)) {
        if (pattern.test(response.config.url)) {
            return {
                message: `错误代码：${response.data.code}\n\n错误信息：${response.data.msg}`,
                title: title,
            };
        }
    }
}

// 添加请求拦截器
axios.interceptors.request.use(
    function(config) {
        // 在发送请求之前做些什么
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    },
);

// 添加响应拦截器
axios.interceptors.response.use(
    function(response) {
        // 请求成功时 需要使用拦截器的URL
        const interceptUrls = {
            登录失败: /api\/login/i,
            上传失败: /api\/uploadUserJs/i,
        };

        if (response.data.code !== 200) {
            const info = process(interceptUrls, response);
            info &&
                Vue.prototype.$alert(info.message, info.title, {
                    dangerouslyUseHTMLString: true,
                    type: "error",
                    confirmButtonText: "确定",
                });
        }
        return response;
    },
    function(error) {
        console.error({
            [error.config.url]: error,
        });
        // 请求失败时 需要使用拦截器的URL
        const interceptUrls = {
            登录失败: /api\/login/i,
        };

        let info = {};

        switch (error.response.status) {
            case 403:
                info.message = `短时间内多次请求`;
                info.title = "403 Forbidden";
                break;

            case 404:
                info.message = `${error.config.url}不存在！`;
                info.title = "404 NOT FOUND";
                break;

            case 500:
                info.message = `服务器内部错误`;
                info.title = "500 Internal Server Error";
                break;

            case 502:
                info.message = `服务器内部错误`;
                info.title = "502 Bad Gateway";
                break;

            default:
                //info可能为undefined
                info = process(interceptUrls, error.response);
                break;
        }

        info &&
            Vue.prototype.$alert(info.message, info.title, {
                type: "error",
                confirmButtonText: "确定",
            });

        return Promise.reject(error);
    },
);

Vue.use(VueAxios, axios);

export default Vue;