<template lang="html">
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="../assets/logo.png" height="56px" />
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <upsert
      v-if="store.upsertVisible"
      :js="store.js"
      :visible="store.upsertVisible"
      @close="store.upsertVisible = false"
    ></upsert>
    <signup
      v-if="signupVisible"
      :visible="signupVisible"
      @close="signupVisible = false"
    ></signup>
    <login
      v-if="loginVisible"
      :visible="loginVisible"
      @close="loginVisible = false"
    ></login>
    <div class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item" v-if="!store.user">
          <div class="buttons">
            <a class="button is-info" @click="signupVisible = true">
              注册
            </a>

            <a class="button is-light" @click="loginVisible = true">
              登录
            </a>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-else
          v-loading="loading"
        >
          <a class="button is-info" style="margin-top:0.1em">
            <i class="el-icon-user-solid" style="margin-right:0.5em"></i>
            {{ store.user }}
          </a>

          <div class="navbar-dropdown">
            <a class="navbar-item" target="_blank" :href="home">
              我的主页
            </a>
            <a class="navbar-item">
              管理脚本
            </a>
            <a
              class="navbar-item"
              @click="
                store.js = {};
                store.upsertVisible = true;
              "
            >
              上传脚本
            </a>
            <a class="navbar-item">
              修改密码
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click="logout()">
              退出登录
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import login from "./user/login";
import signup from "./user/signup";
import upsert from "./script/upsert";
import utils from "./utils";
export default {
  components: {
    login,
    signup,
    upsert,
  },
  data() {
    return {
      loading: false,
      store: utils.store,
      loginVisible: false,
      signupVisible: false,
    };
  },
  mounted() {
    this.store.check(this.axios);
  },
  computed: {
    home: () => utils.url.userdetail(utils.store.user),
  },
  methods: {
    logout() {
      this.loading = true;
      utils
        .leastTime(this.axios.get(utils.api.logOut()), 1e3)
        .then(() => {
          this.$message({
            message: "注销成功！",
            type: "success",
          });
          utils.store.check(this.axios);
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="css"></style>
