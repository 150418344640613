<template>
  <el-dialog
    width="32em"
    :modal="false"
    title="登录"
    :visible="visible"
    @close="cancel"
  >
    <el-form
      v-loading="loading"
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="手机号" prop="username">
        <el-input v-model.number="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">登录</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import utils from "../utils";

export default {
  name: "Login",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let validateNull = (msg) => (rule, value, callback) => {
      if (value === "") {
        callback(new Error(msg));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      store: utils.store,
      form: {
        password: "",
        username: "",
      },
      rules: {
        password: [{ validator: validateNull("请输入密码"), trigger: "blur" }],
        username: [
          { validator: validateNull("请输入用户名/手机号"), trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.loading = false;
      this.$emit("close");
    },
    ok() {
      this.loading = false;
      this.$emit("close", this.form);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.axios
            .post(utils.api.login(), this.stringify(this.form))
            .then((res) => {
              console.log({ login: res });
              if (res.data.code === 200) {
                this.$message({
                  message: "登陆成功",
                  type: "success",
                });
                this.ok();
                this.store.check(this.axios);
              }
            })
            .catch((err) => {
              console.error({ login: err });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="css"></style>
