<template>
  <div>
    <div>
      <el-row>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark ">作者</span>
            <span class="tag is-light has-text-weight-semibold"
              ><el-link
                :href="utils.url.userdetail(js.author ? js.author.uid : '')"
                target="_blank"
                >{{ js.author ? js.author.name : "" }}</el-link
              ></span
            >
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">版本</span>
            <span class="tag is-light">{{ js.version }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">更新时间</span>
            <span class="tag is-light">{{ js.updateTime }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 0.5em">
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">今日安装</span>
            <span class="tag is-light">{{
              js.install ? js.install.today : 0
            }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">总安装</span>
            <span class="tag is-light">{{
              js.install ? js.install.all : 0
            }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">创建时间</span>
            <span class="tag is-light">{{ js.createTime }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 0.5em">
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">兼容</span>
            <span class="tag is-success">{{
              js.compatible ? js.compatible : "无"
            }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">不兼容</span>
            <span class="tag is-warning">{{
              js.incompatible ? js.incompatible : "无"
            }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">许可协议</span>
            <span class="tag is-info">{{
              js.license ? js.license : "无"
            }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 0.5em" type="flex" align="middle">
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-dark">评分</span>
            <el-tooltip
              effect="dark"
              :content="'好评'"
              placement="bottom-start"
            >
              <span class="tag is-success is-light">
                {{ js.score ? js.score.good : 0 }}
              </span>
            </el-tooltip>
            <el-tooltip effect="dark" :content="'一般'" placement="bottom">
              <span class="tag is-warning is-light">
                {{ js.score ? js.score.medium : 0 }}
              </span>
            </el-tooltip>
            <el-tooltip effect="dark" :content="'差评'" placement="bottom-end">
              <span class="tag is-danger is-light">
                {{ js.score ? js.score.bad : 0 }}
              </span>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-success is-medium"
              ><el-link
                :href="js.donationUrl"
                icon="el-icon-coin"
                target="_blank"
                class="has-text-white"
              >
                捐赠作者</el-link
              ></span
            >
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tags has-addons">
            <span class="tag is-success is-medium">
              <el-link
                icon="el-icon-download"
                class="has-text-white"
                :href="js.downloadUrl"
                >直接安装</el-link
              ></span
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "ScriptListItem",

  props: {
    index: Number,
    js: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      utils,
    };
  },

  methods: {},

  computed: {},
};
</script>

<style lang="css"></style>
