<template lang="html">
  <el-dialog
    width="90%"
    :modal="false"
    title="上传脚本"
    :visible="visible"
    @close="cancel"
  >
    <el-row type="flex" justify="center">
      <el-col :span="23">
        <el-form
          v-loading="loading"
          :model="form"
          status-icon
          :rules="rules"
          ref="form"
          label-width="100px"
        >
          <el-form-item label="脚本代码" prop="data">
            <el-input
              type="textarea"
              v-model="form.data"
              :autosize="{ minRows: 3, maxRows: 16 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用说明" prop="info">
            <editor
              ref="infoeditor"
              :initialValue="editorText"
              :options="editorOptions"
              height="30em"
              initialEditType="markdown"
              previewStyle="vertical"
            ></editor>
          </el-form-item>
          <el-form-item label="脚本类型" prop="type">
            <el-radio-group v-model="form.type">
              <el-tooltip
                class="item"
                effect="dark"
                content="脚本不含收费项目"
                placement="top"
              >
                <el-radio :label="1">免费脚本</el-radio>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="免费用户可以使用部分脚本功能"
                placement="top"
              >
                <el-radio :label="2">部分收费</el-radio>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="仅付费用户可以使用脚本，免费用户完全不能使用"
                placement="top"
              >
                <el-radio :label="3">付费脚本</el-radio>
              </el-tooltip>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="可见性" prop="visible">
            <el-radio-group v-model="form.visible">
              <el-tooltip
                class="item"
                effect="dark"
                content="可通过搜索/推荐等途径访问"
                placement="top"
              >
                <el-radio :label="true">公开脚本</el-radio>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="仅可通过链接访问"
                placement="top"
              >
                <el-radio :label="false">半公开脚本</el-radio>
              </el-tooltip>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')"
              >提交</el-button
            >
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import utils from "../utils";
export default {
  name: "Login",
  components: {
    editor: Editor,
  },
  props: {
    js: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入脚本源代码"));
      } else {
        callback();
      }
    };
    return {
      editorText: "",
      loading: false,
      id: "",
      form: {
        data: "",
        info: "",
        visible: true,
        type: 1,
      },
      editorOptions: {
        language: "zh-cn",
        autofocus: "false",
        toolbarItems: [
          "heading",
          "bold",
          "italic",
          "strike",
          "divider",
          "hr",
          "quote",
          "divider",
          "ul",
          "ol",
          "task",
          "indent",
          "outdent",
          "divider",
          "table",
          "link",
          "divider",
          "code",
          "codeblock",
        ],
      },
      rules: {
        code: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (this.js) {
      if (typeof this.js.info === "string") this.editorText = this.js.info;
      if (typeof this.js.visible === "boolean")
        this.form.visible = this.js.visible;
      if (typeof this.js.type === "number") this.form.type = this.js.type;
      if (this.js.code) this.form.data = this.js.code;
      this.id = this.js.id;
    }
  },
  methods: {
    cancel() {
      this.loading = false;
      this.$emit("close");
    },
    ok() {
      this.loading = false;
      this.$emit("close", this.form);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.form.info = this.$refs.infoeditor.invoke("getHtml");
          let res = await utils.leastTime(
            this.axios.post(
              utils.api.upsertuserjs(this.id),
              this.stringify(this.form)
            ),
            1e3
          );
          if (res.data.code === 200) {
            this.$message({
              message: "上传成功！",
              type: "success",
            });
          }
          this.loading = false;
        }
      });
    },
    resetForm(formName) {
      this.$refs.infoeditor.invoke("setMarkdown", "");
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="css">
.te-mode-switch-section {
  line-height: 1rem;
}
</style>
