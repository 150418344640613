<template>
  <el-dialog
    id="signup"
    width="32em"
    :modal="false"
    title="注册"
    :visible="visible"
    @close="close()"
  >
    <el-form
      v-loading="loading"
      :model="form"
      status-icon
      :rules="itemRules"
      ref="form"
      label-width="auto"
    >
      <el-form-item label="手机号" prop="username">
        <el-input
          v-model.number="form.username"
          placeholder="请输入手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name" label="昵称">
        <el-input v-model="form.name" placeholder="设置昵称"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="设置密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="verifyPassword" label="确认密码">
        <el-input
          type="password"
          v-model="form.verifyPassword"
          placeholder="再次输入密码"
        ></el-input>
      </el-form-item>

      <el-form-item
        prop="captcha"
        label="验证码"
        id="captcha"
        :error="captchaerror"
      >
        <el-input placeholder="请输入图形验证码" v-model="form.captcha">
          <template slot="append"
            ><el-image
              id="captcha"
              style="cursor:pointer"
              :src="captchaImage"
              @click="getCaptcha()"
              alt="点击更换图片"
            ></el-image
          ></template>
        </el-input>
      </el-form-item>

      <el-form-item prop="smscaptcha" label="短信验证码" :error="smserror">
        <el-input placeholder="请输入短信验证码" v-model="form.smscaptcha">
          <template slot="append"
            ><el-button
              @click="sendSmsCode()"
              :disabled="!smsAvailibility"
              id="smsButton"
            >
              {{ smsInfo }}
            </el-button></template
          >
        </el-input>
      </el-form-item>
    </el-form>

    <div class="dialog-footer">
      <el-button type="primary" @click="submitForm()">注 册</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";

import utils from "../utils";

let enableSmscodeValidator = false; //是否开启smscode验证

export default {
  name: "Signup",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateUsername = async (rule, value, callback) => {
      // 验证手机号是否合法
      if (
        /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(value)
      ) {
        const response = await this.axios.get(`/api/checkPhone/${value}`);
        switch (response.data.code) {
          case 200:
            callback();
            break;
          case -100:
            callback(new Error("手机号已存在"));
            break;
        }
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    };
    const validateName = async (rule, value, callback) => {
      let nickName = encodeURIComponent(value)
      const response = await this.axios.get('/api/checkName/' + nickName);
      switch (response.data.code) {
        case 200: //用户名合法
          callback();
          break;
        case -100: //用户名存在
          callback(new Error("用户名已存在"));
          break;
      }
    };
    const validatePassword = (rule, value, callback) => {
      const result = this.$refs.form.validateField(
        "verifyPassword",
        (valid) => {
          console.log(valid);
        }
      );
      console.log(result);
      callback();
    };
    const validateVerifyPassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    const validateCaptcha = (rule, value, callback) => {
      if (String(value).length !== 4) {
        callback(new Error("应为4个字符"));
      } else {
        callback();
      }
    };
    const validateSmsCode = (rule, value, callback) => {
      if (!value || value.length !== 4 || isNaN(parseInt(value))) {
        callback(new Error("应为4位数字"));
      }
    };
    return {
      store:utils.store,
      loading: false,
      captchaerror: "",
      smserror: "",
      form: {
        username: "",
        name: "",
        password: "",
        verifyPassword: "",
        captcha: "",
        smscaptcha: "",
      },

      captchaImage: "",
      smsInfo: "获取验证码",
      smsAvailibility: true,
      enableSmscodeValidator: false,
      itemRules: {
        username: [
          { required: true, message: "手机号不能为空" },
          { type: "number", message: "手机号必须为数字" },
          { validator: validateUsername, trigger: "blur" },
        ],
        name: [
          { required: true, message: "昵称不能为空" },
          {
            min: 2,
            max: 12,
            message: "长度应为2-12个字符之间",
            trigger: "blur",
          },
          { validator: validateName, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码" },
          {
            min: 6,
            max: 18,
            message: "长度应为6-18个字符之间",
            trigger: "blur",
          },
          { validator: validatePassword, trigger: "blur" },
        ],
        verifyPassword: [
          { required: true, message: "请再次输入密码" },
          {
            min: 6,
            max: 18,
            message: "长度应为6-18个字符之间",
            trigger: "blur",
          },
          { validator: validateVerifyPassword, trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "请输入图形验证码" },
          { validator: validateCaptcha, trigger: "blur" },
        ],
        smscaptcha: {
          get() {
            if (enableSmscodeValidator) {
              return [
                { required: true, message: "请输入短信验证码" },
                { validator: validateSmsCode, trigger: "blur" },
              ];
            } else {
              return [];
            }
          },
        },
      },
    };
  },
  methods: {
    close() {
      this.loading = false;
      this.$refs.form.resetFields();
      this.$emit("close");
    },
    ok() {
      this.loading = false;
      this.$refs.form.resetFields();
      this.$emit("close", this.form);
    },
    async submitForm() {
      enableSmscodeValidator = true;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const response = await utils.leastTime(this.axios.post(
             "/api/regist",
            this.stringify(this.form)
          ),1e3);
          this.loading = false;
          switch (response.data.code) {
            case 200: //发送成功后返回
              this.ok();
              this.$message({
                message: "注册成功！",
                type: "success",
              });
               this.store.check(this.axios);
              break;
            case 40004: //验证码错误
              this.smserror = "短信验证码错误！";
              this.$message({
                message: "短信验证码错误！",
                type: "error",
              });
              break;
            case 40005: //用户名可能存在错误
              this.$message({
                message: "用户名可能存在错误！",
                type: "error",
              });
              break;
            case 40006: //该用户已注册
              this.$message({
                message: "该用户已注册！",
                type: "error",
              });
              break;
          }
        }
      });
    },

    async getCaptcha() {
      const response = await axios.get("/api/getCaptcha");
      this.captchaImage = response.data.data;
    },
    async sendSmsCode() {
      enableSmscodeValidator = false;
      //其它表单项全部校验通过，才发送
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const response = await this.axios.get(
            `/api/sendCode/${this.form.username}?captcha=${this.form.captcha}&type=1`
          );
          if (response.data.code === -100) {
            this.captchaerror = response.data.msg ;
            this.$message({
              message: response.data.msg,
              type: "error",
            });
          } else if (response.data.code === 40012) {
            this.smserror = "已经发送过了，请稍后再试吧";
            this.$message({
              message: "已经发送过了，请稍后再试吧",
              type: "error",
            });
          } else if(response.data.code === 200){
            this.smsAvailibility = !this.smsAvailibility;
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });
            //等待60秒
            let i = 60;
            let Intv = window.setInterval(() => {
            this.smsInfo = `剩余${i}秒`;
              if(i<=0){
                this.smsAvailibility = !this.smsAvailibility;
                this.smsInfo = "获取验证码";
                clearInterval(Intv)
              }
              i--;  
             }, 1000)
          }else{
            this.$message({
              message: "发生未知异常，请联系站长",
              type: "error",
            });
          }
        }
      });
    },
  },
  mounted() {
    this.getCaptcha();
  },
};
</script>

<style>
#signup .el-form {
  margin: 0 1.2em;
}
#captcha .el-input-group__append {
  width: fit-content !important;
  border: 0px;
  background-color: #fff;
}
#signup .dialog-footer {
  display: flex;
  justify-content: center;
}

#signup .dialog-footer .el-button {
  width: 10em;
}
</style>
