const baseurl = "/api";
const maxPageSize = 10;

let utils = {
    url: {
        userdetail: (id) => `/user/detail/${id}`,
        scriptdetail: (id) => `/script/detail/${id}`,
    },
    api: {
        check: () => baseurl + "/check",
        upsertuserjs: (scriptId) =>
            baseurl + (scriptId ? `/updateUserJs/${scriptId}` : "/uploadUserJs"),
        login: () => baseurl + "/login",
        queryScript: (pageNo, pageSize, type) =>
            baseurl +
            `/queryScriptAll?pageNo=${pageNo}&pageSize=${
                pageSize ? pageSize : maxPageSize
            }&type=${type}`,
        queryScriptByName: (pageNo, pageSize, type, kw) =>
            `/api/queryScriptByName/${encodeURI(kw)}?pageNo=${pageNo}&pageSize=${
                pageSize ? pageSize : maxPageSize
            }&type=${type}`,
        queryScriptByID: (id) => `/api/queryScriptById/${id}`,
        logOut: () => baseurl + "/logOut",
    },
};

let store = {
    _user: "",
    _upsertVisible: false,
    check: (axios) => {
        axios.get(utils.api.check()).then((res) => {
            if (res.data.code !== 200) {
                store.user = null;
            } else {
                store.user = res.data.data;
            }
        });
    },

    _js: "{}",
    get js() {
        return JSON.parse(this._js);
    },
    set js(val) {
        this._js = JSON.stringify(val);
    },
    get upsertVisible() {
        return this._upsertVisible;
    },
    set upsertVisible(val) {
        this._upsertVisible = val;
    },
    get user() {
        return this._user;
    },
    set user(val) {
        this._user = val;
    },
};

function leastTime(promise, time) {
    return Promise.all([
        promise,
        new Promise((res) => {
            window.setTimeout(() => {
                res();
            }, time);
        }),
    ]).then((arr) => arr[0]);
}
window.store = store;
export default {
    store,
    leastTime,
    ...utils,
};

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
